<template>
  <div class="vuse-content-wrapper">
    <v-container fluid>
      <horizontal-menu :menuItems="menuItems" />
      <v-row align="center">
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Cursos completados'"
            :metric="stats.completedCourses ? Math.ceil(stats.completedCourses) : 0"
            :icon="'mdi-clipboard-edit-outline'"
            :loading="loading"
            :helpId="46"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Cursos aprobados'"
            :metric="stats.approvedCourses ? Math.ceil(stats.approvedCourses) : 0"
            :icon="'mdi-order-bool-ascending'"
            :loading="loading"
            :helpId="47"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Progreso General'"
            :metric="
              stats.trainingPercentage
                ? Math.ceil(stats.trainingPercentage) + '%'
                : 0 + '%'
            "
            :icon="'mdi-chart-line-variant'"
            :loading="loading"
            :helpId="48"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Tiempo de capacitación'"
            :metric="
              stats.trainingTime ? Math.ceil(stats.trainingTime) + ' min' : 0 + 'min'
            "
            :icon="'mdi-clipboard-clock'"
            :loading="loading"
            :helpId="49"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { decryptData } from '@/utils/encryption'
  import SingleMetric from '@/components/Stats/SingleMetric'
  import HorizontalMenu from '@/components/App/HorizontalMenu'

  export default {
    name: '',
    components: {
      HorizontalMenu,
      SingleMetric,
    },
    data: () => ({
      loading: false,
      stats: {},
      menuItems: [
        {
          id: 1,
          name: 'Cursos',
          icon: 'mdi-book-open-blank-variant',
          link: '/business/elearning/user-panel/courses',
        },
      ],
    }),
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
    },
    created() {
      this.getStats()
    },
    methods: {
      getStats() {
        this.loading = true
        this.$axios.get('stats-management/elearning-user-stats').then((response) => {
          this.stats = response.data
          this.loading = false
        })
      },
    },
  }
</script>
